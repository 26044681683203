import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MarquesList from "../components/MarquesList"
import Img from "gatsby-image"
import GridVoitures from "../components/GridVoitures"
import TinySlider from "tiny-slider-react"

const VoituresEnPromoMaroc = props => {
  const { data } = props

  const { edges: marques } = data.allTaxonomyTermMarques
  const { edges: models } = data.allTaxonomyTermModels
  const settings = {
    lazyload: true,
    container: "#fixedWidth",
    mouseDrag: true,
    fixedWidth: 400,
    swipeAngle: false,
    speed: 400,
  }
  const marquess = []
  const marquessUnique = []
  models.map(model => {
    if (
      !marquess.includes(model.node.relationships.field_marques.name) &&
      model.node.relationships.field_marques.relationships.field_promo_images
        .length > 0
    ) {
      marquess.push(model.node.relationships.field_marques.name)
      marquessUnique.push({
        marque: model.node.relationships.field_marques,
        cars: [model],
      })
    } else {
      marquessUnique.map(marquessUniqu => {
        if (
          marquessUniqu.marque.name ==
          model.node.relationships.field_marques.name
        ) {
          marquessUniqu.cars.push(model)
        }
      })
    }
  })

  return (
    <Layout>
      <SEO
        title="Voitures en promo au maroc"
        description="Liste des voitures en promotion au maroc, tous les voitures avec une
      liste compléte des prix en promo des voitures au maroc, Mauto vous propose tous les voitures en promo au maroc"
      />

      <section className="section-header bg-dark pb-7 pb-lg-10 text-white">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 text-center">
              <h1 className="mb-2 display-3">Voitures en promo au maroc</h1>
              <p className="lead px-lg-5 mb-5">
                Liste des voitures en promotion au maroc, tous les voitures avec
                une liste compléte des prix en promo des voitures au maroc,
                Mauto vous propose tous les voitures en promo au maroc
              </p>
            </div>
          </div>
        </div>
        <div className="pattern bottom"></div>
      </section>

      <section className="section section-md pb-0">
        <div className="container z-2 mt-n9 mt-lg-n10">
          <div className="row">
            {marquessUnique.map(({ marque: marque }) => (
              <div
                key={marque.id}
                className="col-3 col-md-1 col-lg-1 mb-1 p-1 mb-lg-0"
              >
                <Link
                  className="card border-light animate-up-3"
                  to={`/voitures-en-promo-maroc/#${marque.name.replace(
                    / /g,
                    "_"
                  )}`}
                >
                  <div className="card-body p-3">
                    <Img
                      className="img-fluid image-sm"
                      style={{ width: "45px" }}
                      fluid={
                        marque.relationships.field_image.localFile
                          .childImageSharp.fluid
                      }
                      alt={marque.name}
                    />
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>

      {marquessUnique.map(marquessUniqu => (
        <section
          key={marquessUniqu.marque.id}
          className="section mb-1 p-1 bg-white"
        >
          <div className="container">
            <div
              id={marquessUniqu.marque.name.replace(/ /g, "_")}
              className="d-flex align-items-center"
            >
              <div className="avatar-lg">
                <Img
                  className="img-fluid image-sm"
                  style={{ width: "45px" }}
                  fluid={
                    marquessUniqu.marque.relationships.field_image.localFile
                      .childImageSharp.fluid
                  }
                  alt={marquessUniqu.marque.name}
                />
              </div>
              <div className="ml-3">
                <h3 className="display-4">
                  Voitures {marquessUniqu.marque.name} en promo au maroc
                </h3>
              </div>
            </div>
            <div className="col-lg-12 mb-0">
              <TinySlider settings={settings}>
                {marquessUniqu.marque.relationships.field_promo_images.map(
                  (imagePromo, index) => (
                    <div key={index} style={{ position: "relative" }}>
                      <img
                        className={`tns-lazy-img p-1 `}
                        src={imagePromo.localFile.childImageSharp.fluid.srcWebp}
                        data-src={
                          imagePromo.localFile.childImageSharp.fluid.srcWebp
                        }
                        alt={marquessUniqu.marque.name}
                      />
                    </div>
                  )
                )}
              </TinySlider>
              <div className="card-body">
                <GridVoitures colmd={3} voitures={marquessUniqu.cars} />
              </div>
            </div>
          </div>
        </section>
      ))}
    </Layout>
  )
}

export default VoituresEnPromoMaroc

export const pageQuery = graphql`
  query VoituresEnPromoMaroc {
    allTaxonomyTermModels(filter: { field_ispromo: { eq: true } }) {
      edges {
        node {
          path {
            alias
          }
          name
          id
          field_isnew
          field_ispromo
          relationships {
            field_marques {
              id
              path {
                alias
              }
              name
              relationships {
                field_promo_images {
                  uri {
                    value
                    url
                  }
                  localFile {
                    childImageSharp {
                      resize {
                        src
                      }
                      fluid {
                        base64
                        tracedSVG
                        srcWebp
                        srcSetWebp
                        originalImg
                        originalName
                      }
                    }
                  }
                }
                field_image {
                  uri {
                    value
                    url
                  }
                  localFile {
                    childImageSharp {
                      fluid {
                        base64
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                      }
                    }
                  }
                }
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          field_prix_min
          field_prix_max
          field_chevaux_fiscale
          field_carburant
          field_chevaux
          field_isnew
          field_ispromo
        }
      }
    }
    allTaxonomyTermMarques {
      edges {
        node {
          path {
            alias
          }
          id
          name
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
    allNodeActualites(sort: { fields: created, order: DESC }) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
